.backgroundContainer {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popupContainer {
  background-color: #fff;
  padding: 30px 0 15px 0;
  border: 1px solid #707070;
  border-radius: 8px;
  z-index: 99999999;
  max-height: 90vh;
  max-width: 100%;
  width: 600px;
  overflow: auto;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 13px;
  line-height: normal;
}

.popupContent {
  padding-left: 30px;
  padding-right: 30px;
}
.title {
  font-size: 16px;
  font-family: 'Open Sans Bold';
  color: #5200f5;
}
.removeText {
  color: #fc035d;
}
.fieldsContainer {
  margin-top: 15px;
  max-width: 600px;
}
.textAreaTitle {
  font-size: 14px;
  margin-bottom: 10px;
}
.textAreaContainer {
  width: 100%;
}
.removeContainer {
  margin-top: 15px;
  width: 300px;
  max-width: 100%;
}

.footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e6e6e6;
  text-align: right;
  padding-top: 15px;
  padding-right: 15px;
  padding-left: 30px;
  margin-top: 20px;
}
.footer .footerErrorBackground {
  position: absolute;
  width: 100%;
  height: calc(100% + 15px);
  left: 0;
  top: 0;
  background-color: #fff2f7;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.footer .error {
  font-family: Open Sans SemiBold;
  color: #fc035d;
  display: inline-flex;
  align-items: center;
}

.actions .confirm {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.actions .confirm.disabledConfirm {
  opacity: 0.2;
}
.actions .close {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.loaderContainer {
  display: inline-block;
  bottom: 13px;
  right: -11px;
}
