.container {
  width: 100%;
  margin-top: 10px;
  box-sizing: border-box;
}
.title {
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.categories {
  padding-left: 10px;
}
.categoryNameContainer {
  display: inline-flex;
  align-items: center;
}
.categoryName {
  display: inline-flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 8px;
  font-size: 12px;
  height: 28px;
  line-height: 12px;
  border-radius: 3px;
  background-color: rgba(149, 152, 154, 0.1);
  transition: background-color 0.2s ease-in-out;
}
.categoryNameText {
  margin-right: 4px;
}
.dropDownToolbarIcon {
  margin-left: 4px;
  width: 14px;
  height: 9px;
  cursor: pointer;
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
  user-select: none;
}
.dropDownToolbarIcon:hover {
  opacity: 0.7;
}
.dropDownToolbarIcon.dropDownToolbarIconInactive {
  transform: rotate(180deg);
}
.resultsUnderCategoryContainer.collapsed {
  min-height: 1px;
  opacity: 0;
  margin-top: 0;
  margin-bottom: 4px;
}
.resultsUnderCategoryContainer {
  opacity: 1;
  margin-top: 4px;
  margin-bottom: 12px;
  padding-left: 4px;
  background-origin: border-box;
  transition: opacity 0.3s ease;
}
.resultUnderCategory {
  width: 100%;
  display: flex;
  padding: 2px 8px 2px 8px;
  font-size: 11px;
  border-radius: 3px;
  box-sizing: border-box;
}
.resultUnderCategoryText {
  display: inline-block;
  color: #000;
  margin-right: 4px;

  max-width: calc(100% - 25px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.removeIconContainer {
  display: inline-flex;
  text-align: right;
  user-select: none;
}
.removeIconCategory {
  position: relative;
  height: 22px;
  width: 22px;
  cursor: pointer;
  margin-left: 4px;
}
.removeIconResult {
  position: relative;
  height: 18px;
  width: 18px;
  cursor: pointer;
}
.removeIconCategory:hover,
.removeIconResult:hover {
  opacity: 0.7;
}
.deselected {
  text-decoration: line-through;
}
.marker {
  position: absolute;
  top: 7px;
  left: -2px;
  width: 4px;
  height: 4px;
  background-color: rgb(236 0 139);
  border-radius: 100%;
}
