.button {
  font-size: 13px;
  height: 35px;
  margin-right: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  line-height: 13px;
}
.button:hover {
  opacity: 0.7;
}
.button.buttonLoading {
  cursor: not-allowed;
  animation: loading-animation 1s ease infinite;
}
.error {
  color: #fc035d;
  font-size: 13px;
  position: absolute;
  top: -20px;
  left: 0;
  line-height: normal;
}
.aiIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.popupBackgroundContainer {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
}
.popupContainer {
  background-color: #fff;
  padding: 30px 0 30px 0;
  border: 1px solid #707070;
  border-radius: 8px;
  z-index: 99999999;
  max-height: 90vh;
  overflow: visible;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 13px;
  max-width: 600px;
}

.popupContent {
  max-height: 600px;
  overflow: visible;
  padding-left: 30px;
  padding-right: 30px;
}
.popupTitle {
  font-family: 'Open Sans Bold';
  color: #5200f5;
}
.popupTextContainer {
  margin-top: 15px;
}
.popupButton {
  display: block;
  text-align: center;
  min-width: 200px;
  margin-bottom: 5px;
}
.popupButton.disabledPopupButton {
  background-color: #edeef1;
  cursor: inherit;
}
.popupButton.disabledPopupButton:hover {
  opacity: 1;
}
.errorBold {
  font-family: 'Open Sans SemiBold';
}

.popupContainer.popupErrorContainer {
  width: 660px;
  max-width: inherit;
}
.popupErrorContainer .popupTitle {
  color: #fc035d;
}

.popupContainer.popupLoadingContainer {
  width: 660px;
  max-width: inherit;
  overflow: auto;
}
.popupLoadingContainer .popupContent {
  padding-left: 30px;
  padding-right: 30px;
}
.popupLoadingContainer .popupTitle {
  font-size: 16px;
}
.popupLoadingContainer .aiLoadingInstructions {
  margin-top: 15px;
  margin-bottom: 20px;
}
.popupLoadingContainer .aiLoadingInstructions .warning {
  font-family: 'Open Sans SemiBold';
}
.popupLoadingContainer .fieldsContainer {
  margin-top: 15px;
}
.popupLoadingContainer .subTitle {
  margin-bottom: 10px;
  font-style: italic;
}
.popupLoadingContainer .subTitle.dummyCategoriesTitle {
  margin-top: 20px;
}
.popupLoadingContainer .dummyTextarea {
  font-size: 14px;
  margin-bottom: 10px;
  padding: 6px;
  border-radius: 5px;
  background-color: rgba(149, 152, 154, 0.1);
  height: 50px;
  animation: loading-animation 1s ease infinite;
  width: 100%;
  box-sizing: border-box;
}
.popupLoadingContainer .dummyCategories {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
}
.popupLoadingContainer .dummyCategories .dummyCategory {
  display: inline-block;
  width: 150px;
  padding-left: 10px;
  padding-right: 8px;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  border-radius: 3px;
  background-color: rgba(149, 152, 154, 0.1);
  margin-bottom: 4px;
  animation: loading-animation 1s ease infinite;
}
.popupLoadingContainer .dummyCategories .dummyCategory:nth-child(2) {
  animation-delay: 250ms;
}
.popupLoadingContainer .dummyCategories .dummyCategory:nth-child(3) {
  animation-delay: 500ms;
}

@keyframes loading-animation {
  0% {
    opacity: 1;
    border-left-color: #d4d4d4;
    border-top-color: #d4d4d4;
    border-right-color: #c9c7d4;
    border-bottom-color: #c9c7d4;
  }
  50% {
    opacity: 0.5;
    border-left-color: #c9c7d4;
    border-top-color: #c9c7d4;
    border-right-color: #d4d4d4;
    border-bottom-color: #d4d4d4;
  }
  100% {
    opacity: 1;
    border-left-color: #d4d4d4;
    border-top-color: #d4d4d4;
    border-right-color: #c9c7d4;
    border-bottom-color: #c9c7d4;
  }
}
