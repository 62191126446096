.openQuestionContainer {
  display: flex;
  flex-flow: column;
}
.openQuestionCategoriesContainer {
  min-height: 30px;
}
.openQuestionDistributionContainer {
  max-height: 315px;
  flex: 2;
  overflow-y: scroll;
  overflow-x: visible;
  padding-left: 100px;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-right: 10px;
  border-bottom: 1px solid #dfe0e4;
}
.openQuestionAnswersContainer {
  flex: 2;
  overflow-y: scroll;
  overflow-x: visible;
  padding-left: 100px;
  padding-bottom: 10px;
  padding-right: 10px;
}
.openQuestionAnswerContainer {
  border-bottom: 1px solid #dfe0e4;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #8b8b8d;
  font-size: 13px;
}
.openQuestionAnswerContainer:last-child {
  border-bottom: 0;
}
.openQuestionAnswerContainer .answerText {
  width: 85%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 25px;
}
.openQuestionAnswerContainer .answerText .categoryNameAnswerString {
  margin-right: 4px;
}
.openQuestionAnswerContainer .answerText .categoryName {
  display: inline-block;
  padding: 4px 8px 4px 8px;
  font-size: 11px;
  color: #000;
  margin-right: 4px;
  border-radius: 3px;
  background-color: rgba(149, 152, 154, 0.05);
  transition: background-color 0.2s ease-in-out;
}
.openQuestionAnswerContainer .answerText .categoryName:hover {
  background-color: rgba(83, 0, 242, 0.05);
}
.openQuestionAnswerContainer .answerText .categoryName .categoryNameLabel {
  display: inline-flex;
  align-items: center;
}
.openQuestionAnswerContainer
  .answerText
  .categoryName
  .categoryNameLabel
  .categoryText {
  display: inline-block;
  max-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 4px;
}
.openQuestionAnswerContainer
  .answerText
  .categoryName
  .categoryNameLabel
  .categoryCount {
  color: #707070;
}
.openQuestionAnswerContainer .answerText .categorySingleAnswerRemove {
  color: white;
  margin-left: 8px;
  font-size: 8px;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.openQuestionAnswerContainer
  .answerText
  .categoryName:hover
  .categorySingleAnswerRemove {
  visibility: visible;
  opacity: 1;
}
.openQuestionAnswerContainer .answerTimeTaken {
  width: 15%;
  text-align: right;
  padding-right: 5px;
  box-sizing: border-box;
}
.openQuestionAnswerContainer .selectIconContainer {
  width: 25px;
  height: 25px;
  color: #5200f1;
  font-size: 12px;
  position: absolute;
  cursor: pointer;
  top: 12px;
  z-index: 1;
}
.categoryNameSingleLabelContainer {
  display: inline-block;
}
.visibleBox {
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  cursor: pointer;
  border: 2px solid #5300f2;
  user-select: none;
}
.visibleBox.active {
  background-color: #5300f2;
}
.visibleBox .visibleTick {
  width: 10px;
}
.categoryOverflowInfo {
  position: fixed;
  line-height: 13px;
  padding: 5px 8px;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #dfe0e4;
  z-index: 999999;
  color: #000;
  font-size: 9px;
}
.heartIcon {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  display: inline-block;
  position: relative;
  top: 2px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 0.5;
}
.writeSummaryButtonContainer {
  padding: 20px 10px 20px 100px;
  border-bottom: 1px solid #dfe0e4;
}
.writeSummaryButton {
  font-size: 13px;
  min-height: 30px;
  margin-right: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  line-height: 30px;
  user-select: none;
}
.writeSummaryButton:hover * {
  opacity: 0.7;
}
.writeSummaryButton .writeSummaryButtonAddIcon {
  width: 10px;
  height: 10px;
}
.writeSummaryButton .writeSummaryButtonText {
  display: inline-block;
  padding-left: 8px;
}
