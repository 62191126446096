.row {
  display: flex;
  justify-content: space-between;
}
.textContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  bottom: 4px;
}
.description {
  color: #acacac;
  font-size: 12px;
}
.valueInput {
  font-family: 'Open Sans Regular';
  width: 50px;
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  color: #1b1b1b;
  border: 1px solid #cbcbcb;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-left: 10px;
  text-align: right;
}
.valueInput:disabled {
  color: #acacac;
  background-color: #f5f6f6;
}
.licenceSelectorRow {
  margin-bottom: 10px;
}
.licenceCheckboxContainer {
  margin-right: 15px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px;
}
.licenceCheckboxContainer input {
  margin-right: 5px;
  margin-top: 1px;
  accent-color: #5200f1;
  outline: none;
  cursor: pointer;
}
