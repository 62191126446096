.barContainer {
  font-size: 14px;
  color: #221f1f;
  margin-top: 10px;
  margin-bottom: 20px;
}
.barLabel {
  width: 90%;
  padding-right: 20px;
  margin-bottom: 3px;
  z-index: 9;
}
.title {
  font-family: 'Open Sans SemiBold';
  margin-bottom: 10px;
  font-size: 14px;
}

.barRectangleContainer {
  display: flex;
}
.barSingleRectangleContainer {
  width: 75%;
  height: 11px;
  background-color: #ebecf0;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 3px;
  cursor: pointer;
}
.unfilteredBarContainer {
  height: 15px;
}
.barSingleRectangle {
  height: 11px;
  background-color: #5200f1;
  cursor: pointer;
}
.unfiltered {
  font-size: 10px;
}
.barUnfilteredSingleRectangle {
  height: 5px;
  background-color: #8b8b8d;
  cursor: default;
}

.percentage {
  font-family: 'Roboto Bold';
  width: 10%;
  padding-left: 20px;
}
.answers {
  font-family: 'Roboto Bold';
  width: 15%;
  text-align: right;
  padding-right: 5px;
  box-sizing: border-box;
}
