.content {
  padding: 50px 50px;
  padding-top: 0px;
  padding-bottom: 20px;
}
.subtitle {
  font-family: 'Open Sans SemiBold';
  padding-bottom: 15px;
}
.subtitle.firstGroup {
  border-top: 1px solid #cbcbcb;
  padding-top: 15px;
}
.bottomBorderDiv {
  border-bottom: 1px solid #cbcbcb;
  margin-top: 10px;
}
